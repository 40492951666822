import ImageContainer from '../../components/ui/ImageContainer';

const GoldKinen = () => {
  return (
    <div>
      <div className="">
        <div className="flex items-center justify-center py-9 md:py-12 lg:py-16 ">
          <div className=''>
            <p className="text-[#010103]/54 my-4 text-center  text-base font-normal">
              WHY GOLD KINEN
            </p>
            <p className="text-3xl font-semibold text-[#010103]/80 md:text-5xl">Gold New Future</p>
          </div>
        </div>
      </div>
      <div className="bg-[#D9D9D9]/20  px-9 md:px-20 lg:px-28">
        <div className="py-10 md:py-20 lg:py-8 lg:flex lg:px-36">
          <div className="w-full lg:flex lg:w-1/2 lg:items-center lg:justify-center">
            <div className="">
              <ImageContainer src={'image/goldKinen/goldKinen1.svg'} alt="service1" className="" />
            </div>
          </div>
          <div className="flex items-start w-full pt-8 lg:w-1/2 lg:pl-16">
            <div>
              <p className="py-4 text-2xl font-semibold text-[#010103]/80">Why Gold</p>
              <p className="text-[#010103]/54 text-base font-normal text-justify">
                There is a strong connection between people and gold. Perhaps no other
                precious metal has had such a vast impact on us. Gold has maintained its
                value over the long-term, making it a great store of wealth. Most people
                agree that there is something extremely satisfying about investing in
                gold, let it be coins or bars. There is a particularly good feeling in buying
                and then physically holding gold coins and feeling the weight of each coin.
                Historically, it has outperformed most of the world’s currencies particularly
                during times of uncertainty and market stress.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-16 space-y-8 bg-white md:pt-20 px-9 md:px-20 lg:space-y-0 lg:px-28">
        <div className="w-full lg:flex lg:items-center lg:justify-center">
          <div className="">
            <p className="text-start text-3xl font-semibold text-[#010103]/80 md:text-center md:text-5xl">
              Gold Over Time
            </p>
            <p className="text-[#010103]/54 pt-6 text-xl font-normal text-center lg:max-w-4xl">
              As an investment, gold prices tend to rise typically when other investments decline.
Saving in gold is a stable way to protect your finances and secure your future.
Gold coins/bars are also a strong investment as a long-term asset and is a bulwark against inflation.
            </p>
          </div>
        </div>
        <div className="w-full pt-10 lg:flex lg:items-center lg:justify-center">
          {/* <GoldChart/> */}
          <ImageContainer src="image/goldKinen/goldKinen3.png" alt="goldKinen3" className="lg:pl-8 max-h-[500px]" />
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-24 lg:mt-28 px-9 md:px-20 lg:px-28 mb-28">
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="flex items-center justify-center w-full mr-0 lg:mr-22 lg:w-1/2 space-x-4">
            <ImageContainer
              src="image/goldKinen/Bar-Front-Side.png"
              alt="goldKinen2"
              className="w-auto h-96"
            />
            <ImageContainer
              src="image/goldKinen/Bar-Back-Side.png"
              alt="goldKinen2"
              className="w-auto h-96"
            />
          </div>
          <div className="w-full lg:w-1/2">
            <p className="text-[#010103]/54 pb-2 text-base font-normal uppercase">
              Why gold from GOld KInen
            </p>
            <p className="text-2xl font-semibold capitalize text-[#010103]/80 md:text-5xl">
              We want you to save,  not spend
            </p>
            <div className="pt-8 pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">Your gold is 100% secured</p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                You are the legal owner of the gold you purchase. Your gold cannot be used or
                taken out of its vault by anyone without your consent. Your gold remains
                yours even if your gold provider goes out of business.
              </p>
            </div>
            <div className="pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">Your gold is safeguarded</p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                Any gold you buy with us is stored in the trusted vault of our locker 
                partners. Your gold is fully insured against all risks at full replacement
                value.
              </p>
            </div>
            <div className="pb-4">
              <p className="text-[#010103]/8 text-2xl font-semibold">
                Your gold is always redeemable
              </p>
              <p className="text-[#010103]/54 py-2 text-base font-normal text-justify">
                Redemptions start in whole grams, from 2 grams. Each GOLD KINEN gold bar is
                sealed and comes with the assayer’s signature, the bar’s unique serial
                number, purity and weight. You can check for yourself the authentication of
                your purchased Gold coin/bar by the certified Hallmark seal by BAJUS
                (Bangladesh Jewelers Association) on top of your product.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoldKinen;
