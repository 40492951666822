import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollTotop';
import Routes from './routes/Routes';
import './styles/global.css';
import './styles/tailwind.css';

function App() {
  return (
    <div>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default App;
