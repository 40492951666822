import { useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import {  BsFillTelephoneFill } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
import CustomModal from './CustomModal';
import ImageContainer from './ImageContainer';

function Navbar() {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleLinkOnClick = () => setIsOpen(false);

  const [showSearchModal, setShowSearchModal] = useState(false);

  // const handleLinkOnClick = () => setIsOpen(false)

  const handleModalOnClose = () => setShowSearchModal(false);

  return (
    <nav>
      

      <div
        style={{ backdropFilter: 'blur(2px)' }}
        className="sticky top-0 z-50 bg-[#081939] py-2 shadow-lg"
      >
        <div
          ref={ref}
          className="relative overflow-hidden transition-all duration-500 ease-in-out px-9 md:px-20 lg:px-28"
          style={{ maxHeight: isOpen && ref.current ? 800 : 60 }}
        >
          <div className="flex justify-between">
            <div className="mr-0 lg:mr-2">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <ImageContainer
                  src="image/navbar/gklogo.png"
                  alt="gklogo"
                  className="object-scale-down h-18 w-44 md:max-w-md xs:object-fill"
                />
              </Link>
            </div>
            {/* Medium screen links STARTS */}
            <div className="hidden lg:flex lg:text-lg">
              <div className="flex space-x-6">
                <NavLink
                  to="/services"
                  className="mt-4 space-y-2 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  Services & Benefits
                </NavLink>
                <NavLink
                  to="/gold-kinen"
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          textDecoration: 'underline #e9be5a 2px',
                          textUnderlineOffset: '6px',
                        }
                      : {}
                  }
                >
                  Why Gold Kinen
                </NavLink>
                <a
                  href="/#how-it-works"
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                >
                  How it works
                </a>
                <a
                  href="/#about-us"
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                >
                  About Us
                </a>
                <a
                  href="/#faq"
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                >
                  FAQ
                </a>
                <a
                  href="/#contact-us"
                  className=" mt-4 text-sm font-medium text-center text-white hover:text-[#e9be5a] whitespace-nowrap"
                >
                  Contact Us
                </a>
                <a>
                  <button
                    // onClick={() => setShowSearchModal(true)}
                    className="py-4 text-base font-semibold rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br focus:outline-none px-14 text-primary whitespace-nowrap hover:text-white"
                  >
                    Download App
                  </button>
                </a>
                <div className="flex mt-4 space-x-2">
                  <span className="mt-1 text-white">
                    <BsFillTelephoneFill size={14} />
                  </span>
                  <span className="text-base font-normal text-white">09610-964653</span>
                </div>
              </div>
            </div>
            {/* Medium screen links ENDS */}
            <div className="flex justify-end space-x-4 lg:hidden">
              {/* <a>
                  <button className="py-4 text-base font-semibold rounded-lg bg-secondary px-14 text-primary whitespace-nowrap">
                    Download App
                  </button>
                </a> */}

              <button
                type="button"
                className="p-2 text-white transition duration-500 ease-in-out cursor-pointer lg:hidden"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
              </button>
            </div>
          </div>
          {/* small screen links STARTS */}
          {isOpen && (
            <div className="p-2 my-4 space-y-2 rounded-lg lg:hidden">
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/services"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  Services & Benefits
                </a>
              </div>
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/gold-kinen"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  Why Gold Kinen
                </a>
              </div>
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/#how-it-works"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  How it works
                </a>
              </div>
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/#about-us"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  About
                </a>
              </div>
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/#faq"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  FAQ
                </a>
              </div>
              <div>
                <a
                  onClick={handleLinkOnClick}
                  href="/#contact-us"
                  className="px-2 mt-2 text-sm font-medium text-white"
                >
                  Contact Us
                </a>
              </div>
              <div>
                <a>
                  <button className="px-6 py-4 text-base font-semibold rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br text-primary">
                    Download App
                  </button>
                </a>
              </div>
            </div>
          )}
          {/* small screen links ENDS */}
        </div>
      </div>
      <CustomModal open={showSearchModal} onClose={handleModalOnClose}>
        <div className="relative mb-32">
          <h1 className='py-10 text-xl font-semibold text-center'>Download App</h1>
         
          <div className="pb-10 space-x-4 md:flex lg:pb-0">
            <Link to="/home">
              {' '}
              <ImageContainer src="image/home/google.png" alt="google" className="" />
            </Link>
            <Link to="/home">
              {' '}
              <ImageContainer src="image/home/app.png" alt="app" className="" />
            </Link>
          </div>
        </div>
      </CustomModal>
    </nav>
  );
}

export default Navbar;
