import { useRef, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

interface props {
  title: string | number;
  body: string | number | JSX.Element;
  backgroundColor?: string;
}

function Accordion({ title, body, backgroundColor }: props) {
  const ref = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState(false);

  return (
    // <button
    //   type="button"
    //   onClick={() => setSelected((v) => !v)}
    //   className={` cursor-pointer border-white/50 ${selected && 'pb-4'}`}
    // >
    //   <div className="flex justify-between py-2 rounded-lg">
    //     {/* <div className="flex items-center justify-between lg:mx-60 xl:mx-40 py-5 border-t border-[#E0E0E0]"> */}
    //     <p className="font-semibold text-[#010103]/80">{title}</p>
    //     <span className="flex">
    // {selected ? (
    //   <p className="h-7 w-7 rounded-full bg-[#B5BAC4]/20">
    //     <FaMinus className="w-5 h-5 pt-2 pl-2 text-primary" />
    //   </p>
    // ) : (
    //   <p className="h-7 w-7 rounded-full bg-[#B5BAC4]/20">
    //     <FaPlus className="w-5 h-5 pt-2 pl-2 text-primary" />
    //   </p>
    // )}
    //     </span>
    //     {/* </div> */}
    //   </div>
    //   <div
    //     ref={ref}
    //     className="relative overflow-hidden transition-all duration-500"
    //     style={{ maxHeight: selected && ref.current ? ref.current.scrollHeight : 0 }}
    //   >
    //     <p className="p-2 mb-4 rounded-lg text-janttNavyBlue">{body}</p>
    //   </div>
    // </button>
    <div
      onClick={() => setSelected((v) => !v)}
      className={` cursor-pointer border-white/50 ${selected && 'pb-4'}`}
    >
      <div className="flex justify-between py-5 rounded-lg border-t border-[#E0E0E0]">
        <h3 className="text-xl font-semibold text-[#010103]/80">{title}</h3>
        <div className="flex">
          {selected ? (
            <p className="h-7 w-7 rounded-full bg-[#B5BAC4]/20">
              <FaMinus className="w-5 h-5 pt-2 pl-2 text-primary" />
            </p>
          ) : (
            <p className="h-7 w-7 rounded-full bg-[#B5BAC4]/20">
              <FaPlus className="w-5 h-5 pt-2 pl-2 text-primary" />
            </p>
          )}
   
        </div>
      </div>
      <div
        ref={ref}
        className="relative overflow-hidden transition-all duration-500"
        style={{ maxHeight: selected && ref.current ? ref.current.scrollHeight : 0 }}
      >
        <div className={`text-[#B5BAC4]/54 bg-[#F8F8F9] mb-4 rounded-lg p-4`}>{body}</div>
      </div>
    </div>
  );
}

export default Accordion;
