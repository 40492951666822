import { IFaqData, IHeroVideos, IMoreFaqData, IVideo } from '../types';

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;
export const durationRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/;

export const videos: IVideo[] = [
  {
    video: 'image/slider/video1.mp4',
  },
  {
    video: 'image/slider/video2.mp4',
  },
  {
    video: 'image/slider/video1.mp4',
  },
];

export const HeroVideos: IHeroVideos[] = [
  {
    video: 'image/home/HeaderCover1.png',
  },
  {
    video: 'image/home/HeaderCover2.png',
  },

]

export const faqData: IFaqData[] = [
  {
    title: '1.	What type of gold does Gold Kinen use?',
    body: 'We provide 22 Karat Hallmarked and Certified gold coins and bars',
  },
  {
    title: '2.	What is the Purity of Gold offered by Gold Kinen?',
    body: 'We provide 22 Karat Gold (91.6% pure).',
  },
  {
    title: '3.	How do I download Gold Kinen App?',
    body: 'The Gold Kinen App is available on Android (version 7 and above) and iOS (version 8 and above)',
  },
  {
    title: '4.	I already have a Gold Kinen account on my mobile. How do I access it on the web?',
    body: 'Currently we are not offering our transactional services through our web portal.',
  },
  {
    title: '5.	What is KYC? Why is KYC needed?',
    body: "KYC means Know Your Customer and sometimes Know Your Client. KYC or KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time. The safety and security of your gold is always our highest priority, and we are continuously working to improve these aspects. We have designed the Gold Kinen experience to prevent the misuse of your account or unauthorized access to your gold. This will ensure a much safer experience for you, while also allowing you to buy, sell, collect or gift gold.",
  },
];

export const moreFaqData: IMoreFaqData[] = [
  {
    title: '1.	What type of gold does Gold Kinen use?',
    body: 'We provide 22 Karat Hallmarked and Certified gold coins and bars',
  },
  {
    title: '2.	What is the Purity of Gold offered by Gold Kinen?',
    body: 'We provide 22 Karat Gold (91.6% pure).',
  },
  {
    title: '3.	How do I download Gold Kinen App?',
    body: 'The Gold Kinen App is available on Android (version 7 and above) and iOS (version 8 and above)',
  },
  {
    title: '4.	I already have a Gold Kinen account on my mobile. How do I access it on the web?',
    body: 'Currently we are not offering our transactional services through our web portal.',
  },
  {
    title: '5.	What is KYC? Why is KYC needed?',
    body: "KYC means Know Your Customer and sometimes Know Your Client. KYC or KYC check is the mandatory process of identifying and verifying the client's identity when opening an account and periodically over time. The safety and security of your gold is always our highest priority, and we are continuously working to improve these aspects. We have designed the Gold Kinen experience to prevent the misuse of your account or unauthorized access to your gold. This will ensure a much safer experience for you, while also allowing you to buy, sell, collect or gift gold.",
  },

  {
    title: '6.	Is nominee mandatory for Gold Kinen?',
    body: 'Yes, assigning a nominee is mandatory in Gold Kinen App.',
  },
  {
    title: '7.	How much does it cost to open an account in Gold Kinen?',
    body: 'Setting up a Gold Kinen account is free. There are fees associated with your transactions that are detailed on the app at the time of the transaction. You can also check our latest schedule of charges (hyperlinked).',
  },
  {
    title: '8.	Can I get Gold Kinen account without a mobile phone? / Does it work from my desktop?',
    body: 'No. Gold Kinen currently works on mobile phones(iOS/Android) only.',
  },
  {
    title: '9.	Is there any charges for the services offered in Gold Kinen?',
    body: 'Yes. Please check our latest schedule of charges (hyperlinked).',
  },
  {
    title: '10.	What is the minimum criteria to open a Gold Kinen account?',
    body: 'To open a Gold Kinen account you must be 18 years or above, and reside in Bangladesh where we operate and have a valid NID. ',
  },

  {
    title: '11.	During transaction, why have I been asked for an authentication/ OTP code?',
    body: 'You have been asked for an authentication/OTP code during transactions as an additional security measure.',
  },
  {
    title: '12.	Can I have a joint account for Gold Kinen?',
    body: 'No, you cannot have a joint account for Gold Kinen.',
  },
  {
    title: '13.	How do I update my phone number?',
    body: 'Please note that users may only update their phone numbers via communicating customer care team by calling XXXXX ',
  },
  {
    title: '14.	How do I change language on the app?',
    body: 'You can switch the language between English & Bangla from Settings>Language Toggle (Bangla/English)',
  },
  {
    title: '15.	How do I set up 2-factor authentication?',
    body: 'Currently we are using only PIN to access the app. Two-factor authentication, or 2-step verification, is an additional security measure used only in registration process and while logging in from a new device',
  },

  {
    title: '16.	How do I connect my bank account with Gold Kinen app?',
    body: 'You can connect your bank account from Settings> Profile> Payment. While setting up your bank account you will be required to provide Account Name, Account Number, Bank Name, District, Branch Name. ',
  },

  {
    title: '17.	How to buy gold in Gold Kinen?',
    body: 'You can purchase gold in Gold Kinen via Buy Gold option by inputting the amount/quantity you want to buy and confirming your payment through mobile wallet or Debit/Credit card.',
  },
  {
    title: '18.	How many times I can buy gold in a day?',
    body: 'You can buy unlimited times in a day.',
  },
  {
    title: '19.	How much gold can I buy from my Gold Kinen account in a single transaction? ',
    body: 'You can buy up to BDT 300,000 worth of gold in a single transaction',
  },
  {
    title: '20.	What is a buy price of Gold?',
    body: 'Buy price of the gold is the BAJUS declared price every day. For more information on price please visit https://www.bajus.org/ ',
  },
  {
    title: '21.	What is the processing time for buying gold?',
    body: 'Buying gold transactions are processed immediately.',
  },

  {
    title: '22.	Do I have to connect my bank account to avail Gold Kinen app services?',
    body: 'You do not have to connect your bank account to avail all the Gold Kinen app services. Bank Account/Mobile wallet is only needed to avail the sell gold option. ',
  },

  {
    title: '23.	Can I buy gold with other currencies except BDT?',
    body: 'No. You can only buy gold with the currency of the country we currently operate in. Which is BDT.',
  },
  {
    title: '24.	What is the minimum amount in BDT needed to buy gold on Gold Kinen App?',
    body: 'The minimum amount needed to buy gold in Gold Kinen app is BDT  500. ',
  },
  {
    title: '25.	Where is the gold stored when you buy gold?',
    body: 'The gold is stored in a storage facility secured by a leading storage provider of Bangladesh. Furthermore, your gold is fully insured by one of the most renowned Insurance companies of Bangladesh.',
  },
  {
    title: '26.	What if I enter the wrong amount when buying gold?',
    body: 'You can only buy up to the amount covered by the available BDT balance of your Mobile wallet/Bank account/Credit Card. Once a transaction is executed it can not be reversed.',
  },
  {
    title: '27.	How long does it take to process the amount of sold gold?',
    body: 'Refund/sold amount of gold is credited within 3 working days(as per BEFTN & Bangladesh Bank rules). Any delays are subject to delays at payment gateway or financial institution’s end.',
  },

  {
    title:
      '28.	What happens if the sales proceeds are not credited to me the maximum processing days?',
    body: 'If you do not receive the refund/sold amount to your designated mobile wallet/bank account within stipulated timeline you are requested to communicate Customer Care team by calling XXXXX ',
  },

  {
    title: '29.	Where can I take the money from sold/refund amount of gold from Gold Kinen app?',
    body: 'You can receive the money of your sold/refund amount of gold via mobile wallet and banking channel.',
  },
  {
    title: '30.	How many times a day can I sell gold? ',
    body: 'You can sell gold unlimited number of times in a day.',
  },
  {
    title: '31.	What is the maximum sell gold amount from my Gold Kinen account? ',
    body: 'You can sell gold up to the available gold balance amount from your account (subject to applicable charges and bank/mobile banking guideline).',
  },
  {
    title: '32.	What is the minimum amount of gold can I sell from my Gold Kinen account? ',
    body: 'Minimum gold you can sell is BDT 500 of the available gold balance amount from your account (subject to applicable charges and bank/mobile banking guideline).',
  },
  {
    title: '33.	What if I enter the wrong amount when buying or selling gold?',
    body: 'Once a gold buy/sell transaction is executed in the Gold Kinen app, it cannot be reversed.',
  },

  {
    title:
      '34.	I sold gold but the amount has not yet arrived at my bank account/mobile wallet, what should I do? ',
    body: 'If you do not receive the refund/sold gold amount within stipulated timeline (3 working days) in your designated bank account/mobile wallet, you are requested to communicate immediately with Customer Care team by calling XXXXX ',
  },
  {
    title: '35.	How to collect physical gold?',
    body: 'The physical gold can be collected via Collect gold option.',
  },
  {
    title: '36.	How does the gold get delivered to me?',
    body: 'Your gold will be delivered to your designated address given in your Gold Kinen App, by our secured and insured delivery partners.',
  },
  {
    title:
      '37.	Should the customer need to show any identity proof at the time of taking the physical gold coins?',
    body: 'Customers will be required to show their NID, while receiving delivery.',
  },

  {
    title: '38.	What is the limit for collecting gold in a day?',
    body: 'Currently there is no limit for collecting gold in a day.',
  },
  {
    title: '39.	Can I change my delivery address?',
    body: 'You can change your delivery address from Profile section. However, your account will remain locked until the requested change has been approved.',
  },
  {
    title: '40.	Are there any charges for delivery of gold?',
    body: 'Currently there are no charges for Collecting Gold. However, delivery fees might be applicable as per the Scheduled of charges of delivery partner which will need to be paid by the user directly to our delivery partner.',
  },
  {
    title: '41.	How does Gold Kinen ensure secured delivery of gold?',
    body: 'Gold Kinen ensures secured delivery via appointing one of the country’s leading logistics company. All our deliveries are fully insured.',
  },
  {
    title: '42.	Can someone else redeem the physical gold on my behalf?',
    body: 'No one can redeem the physical gold on your behalf. You must redeem physical gold yourself through the collect gold option in your app. ',
  },

  {
    title: '43.	What happens if my gold gets lost during delivery?',
    body: 'As the deliveries are fully insured, for any unfortunate event that the gold gets lost, customer will be refunded after the necessary investigation, given that the event is not due to customer’s negligence.',
  },
  {
    title: '44.	How do I gift gold?',
    body: 'You can gift gold by selecting the “Gift Gold” option on the Gold Kinen App',
  },
  {
    title: '45.	Is there any limit to how much gold can you gift in a day?',
    body: 'Currently there is no limit on Gift gold in a day.',
  },
  {
    title: '46.	What if someone doesn’t accept the gift?',
    body: 'Gifted Gold is directly transferred to the recipient’s account. There is currently no option for not accepting Gift Gold. ',
  },
  {
    title: '47.	Is there any expiry date for gifts?',
    body: 'No, there is no expiry date for gifts.',
  },

  {
    title: '48.	How can I check the status of the gifts shared?',
    body: 'Any Gift sent/received will be notified via notification in your inbox of your Gold Kinen App. You can also check the Gifted gold details from the gift tab in the history option of the Gold Kinen App.',
  },
  {
    title: '49.	Is there any way to track the gold sent and received?',
    body: 'Any Gift sent/received will be notified via in app notification. You can also check the Gifted gold details from the gift tab in history option of the Gold Kinen App.',
  },
  {
    title: '50.	Can I gift gold to a recipient who does not have a Gold Kinen account?',
    body: 'No currently you cannot Gift Gold to a person who does not have a Gold Kinen account',
  },
  {
    title: '51.	How is the gold rate calculated for gifting gold?',
    body: 'Currently Gift Gold is free of charges. The gold rate will be applied as per the day’s market rate as mentioned in the Gold Kinen app.',
  },
  {
    title: '52.	Can I send gold overseas?',
    body: 'No. You cannot send gold overseas.',
  },

  {
    title: '53.	How do I know if someone has received the gold I sent?',
    body: 'As all the Gift Gold requests are processed in real time, In app notification indicates that the gold has been transferred to the recipient. You can also check the Gifted gold details from the gift gold tab in the history option of the Gold Kinen App.',
  },

  {
    title: '54.	I sent some gold to a new user, but they never signed up. What happens now?',
    body: 'If the recipient of your gold is not a registered Gold Kinen user, the transaction will not be successful.',
  },
  {
    title: '55.	What happens if I send gold to the wrong person?',
    body: 'All transactions initiated by you on the Gold Kinen app are irreversible. You are solely responsible for the transactions initiated by you. ',
  },
  {
    title:
      '56.	Should the receiver need to show any identity proof at the time of taking the physical gold?',
    body: 'Customers will be required to show the valid NID, while receiving delivery.',
  },
  {
    title: '57.	What are the fees and service charges of Gold Kinen?',
    body: 'Please check our latest schedule of charges (hyperlinked).',
  },
  {
    title: '58.	How much does it cost to collect physical gold from Gold Kinen? ',
    body: 'Currently there are no charges for collecting gold in Gold Kinen. However, delivery fees will be applicable as per the scheduled of charges of delivery partners.',
  },

  {
    title: '59.	What are the fees associated with each payment option? ',
    body: 'Please check our latest schedule of charges (hyperlinked) for fees.',
  },

  {
    title: '60.	How do I know how much my gold is worth?',
    body: 'No. You can only buy gold with the currency of the country we currently operate in. Which is BDT.',
  },
  {
    title: '61.	Do you charge for the storage of my gold?',
    body: 'The charge for storage for the gold is included in the service fee you pay when you’re buying gold in the Gold Kinen app.',
  },
  {
    title: '62.	How do I know the gold market rates are genuine?',
    body: 'Our gold rates are determined by the official rates published by BAJUS. You can go to BAJUS website and match their rates with our rates.',
  },
  {
    title: '63.	Where is my gold stored?',
    body: 'Your gold is secured in high-security vaults located in Bangladesh which is operated by one of the country’s leading security companies.',
  },
  {
    title: '64.	Can I see my gold?',
    body: 'Yes, you can! When you use our “Collect Gold” service to take the physical gold as delivery at your doorstep.',
  },

  {
    title: '65.	Can I withdraw my gold physically?',
    body: 'You can withdraw physical gold using “Collect Gold” service to take the physical gold as delivery at your doorstep.',
  },

  {
    title: '66.	How do I know my gold is secure?',
    body: 'Your gold is secured in high-security vaults located in Bangladesh which is managed by one of the top security companies in the country. Furthermore, the gold is fully insured by one of the country’s leading insurance companies.',
  },
  {
    title: '67.	What happens to my gold if anything happens to Gold Kinen?',
    body: 'You are entitled to the gold you buy and store at Gold Kinen. In the unlikely event that something was to happen to Gold Kinen, your gold will be refunded or delivered to you.',
  },
  {
    title: '68.	How do I know my gold is genuine?',
    body: 'We provide 22 karat hallmarked gold coins and bars which are certified by our certification partner Bangla Gold/Dhaka Gold.',
  },
  {
    title: '69.	Is my Gold insured?',
    body: 'Your gold is fully insured by one of the country’s leading insurance companies.',
  },
  {
    title: '70.	Which are the organizations which certifies the gold offered by Gold Kinen?',
    body: 'Our gold coins and bars are certified by Bangla Gold/Dhaka Gold.',
  },

  {
    title: '71.	What happens to Gold Kinen account if the account holder dies?',
    body: 'The legal heir of the account holder can claim the account holder’s account by following the account handover process. In such cases they are requested to contact Gold Kinen customer care by calling XXXX.',
  },
  {
    title: '72.	How can I refer my friends?',
    body: 'You can refer a friend by selecting the “Refer a friend” from the homepage from the Gold Kinen app or you can share your unique refer code to the person you want to refer. ',
  },
  {
    title: '73.	What is the reward for referral?',
    body: 'After a successful referral upon the first buy gold of the referee, both referee and referrer may get referral bonus, which may vary from time to time.',
  },
  {
    title: '74.	How can I check that I have received my reward by referral?',
    body: 'We will send you a notification when you receive a reward in your inbox in your Gold Kinen app. ',
  },

  {
    title: '75.	I have not received my reward, why?',
    body: 'If you have not received your reward after applying the promo/refer code within stipulated time, please contact Gold Kinen customer care at XXXX.',
  },
  {
    title: '76.	My promo code/refer code did not work, why?',
    body: 'If you see an error message after you applied your promo/refer code, it may be because:1) the promo code has expired2) you do not meet the campaign criteria to use the promo/refer code3) The promo/refer code is invalidFor any other reason please contact Gold Kinen customer care at XXXX.',
  },
  {
    title:
      '77.	How long does it take to receive my reward after my referee used Gold Kinen app successfully?',
    body: 'You would receive the reward within ten working days of the fulfillment of referral conditions.',
  },
  {
    title: '78.	Can I use one Gold Kinen promo code concurrently with other Gold Kinen Promo Codes?',
    body: 'No, you can only use one code at a time.',
  },
  {
    title: '79.	Who will manage Customer complaints?',
    body: 'Customer complaints are handled by our dedicated Customer Services team. You may contact our customer care team by calling XXXXX. ',
  },

  {
    title: '80.	How do I report a technical problem to Gold Kinen?',
    body: 'You may contact our customer care team by calling XXXXX',
  },
  {
    title: '81.	I’ve lost my mobile phone, what should I do?',
    body: 'If such an unfortunate event happens, kindly contact our Customer Support Team immediately by calling XXXXX and request to “lock” your Gold Kinen account. ',
  },
  {
    title:
      '82.	I’ve changed my mobile phone, how can I access my Gold Kinen account from my new mobile device?',
    body: 'Download the Gold Kinen app on your new mobile phone and recover your account. TECH TEAM ',
  },
  {
    title: '83.	What happens if I’ve forgotten my PIN?',
    body: 'You are requested to contact Gold Kinen customer care by calling XXXX and initiate PIN replacement process.',
  },
  {
    title: '84.	 What if I want to change my PIN?',
    body: 'You may reset your PIN from Profile > Settings > Login Preferences on the Gold Kinen app.',
  },

  {
    title: '85.	What happens if I’ve entered the wrong PIN?',
    body: 'You will have 5 attempts at login. If you’ve entered the incorrect PIN more than 5 times, your PIN will be disabled. For recovering your account you are requested to contact our customer care team by calling XXXXX',
  },
  {
    title: '86.	How can I setup my PIN?',
    body: 'During registration, you will be asked to set and confirm your PIN after the phone number verification via OTP. You may reset your PIN from Profile > Settings > Login Preferences on the Gold Kinen app.',
  },
  {
    title:
      '87.	I’m trying to complete my profile on the Gold Kinen app, but I’m facing issues, what should I do?',
    body: 'In case you are facing any issues with completing/updating your profile, you may contact our customer care team by calling XXXXX.',
  },
  {
    title:
      '88.	I can’t take a picture for my identification/NID using camera while in Gold Kinen app. What should I do?',
    body: 'To make sure that your current app settings are optimal, you can check to see if permission for Camera is enabled for Gold Kinen App. If you are still facing issues, you are requested to contact our customer care team by calling XXXXX. ',
  },
  {
    title: '89.	How can I get my Gold Kinen account statement?',
    body: 'You can check the history option for transaction history.',
  },

  {
    title: '90.	How can one change their registered mobile number on Gold Kinen?',
    body: 'You cannot change your registered mobile number.',
  },

  {
    title: '91.	I’m facing issues with bank account verification, what should I do?',
    body: 'For delays in bank account verification, you are requested to contact our customer care team by calling XXXXX. ',
  },
  {
    title: '92.	How do I change my email address registered with Gold Kinen?',
    body: 'You can change your email address from the profile section in the Gold Kinen app.',
  },
  {
    title: '93.	Why is my account locked?',
    body: 'There may be multiple reasons for which your account is locked. To know the details, you are requested to contact our customer care team by calling XXXXX. ',
  },
];
