import { Parallax, ParallaxLayer } from '@react-spring/parallax';

export default function GoldConvenience() {
  return (
    <div className='' style={{}}>
    
      <Parallax pages={3} >
      

        <ParallaxLayer
          offset={1}
          speed={1}
          // style={{ ...alignCenter, paddingRight: '100px', paddingLeft: '100px' }}
        >
          <div className="flex justify-start pt-20 px-9 md:px-24 lg:px-96">
            <div className="py-16 w-50 h-80 md:w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
              <p className="text-center text-3xl font-semibold text-[#010103]/80">Save in gold</p>
              <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                Buy and store original hallmark certified 22 karat gold anytime anywhere
              </p>
            </div>
          </div>

          <div className="flex justify-end pt-20 px-9 md:px-24 lg:px-96">
            <div className="py-16 h-80 w-50 md:w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
              <p className="text-center text-3xl font-semibold text-[#010103]/80">
                Gold in BDT 500
              </p>
              <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                Buy gold starting from BDT 500 according to official market rate
              </p>
            </div>
          </div>

          <div className="flex items-start justify-start py-20 px-9 md:px-24 lg:items-center lg:justify-center lg:px-96">
            <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
              <p className="text-center text-3xl font-semibold text-[#010103]/80">
                Secure Future <br /> in Gold
              </p>
              <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                Increasing gold value over time ensures your investments and capital gain
              </p>
            </div>
          </div>

          <div className="flex justify-start px-9 md:px-24 lg:px-96">
            <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
              <p className="text-center text-3xl font-semibold text-[#010103]/80">
                Redeem <br /> Physical Gold
              </p>
              <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                You can redeem gold for a minimum of 2 grams in coin or bar format
              </p>
            </div>
          </div>
          <div className="flex justify-end pt-20 px-9 md:px-24 lg:px-96">
            <div className="py-16 h-80 w-72 rounded-xl bg-gradient-to-r from-[#e9be5a] to-[#c6952c]">
              <p className="text-center text-3xl font-semibold text-[#010103]/80">
                Ethical <br /> Investment <br /> In Gold
              </p>
              <p className="p-4 text-center text-xl font-normal text-[#010103]/50">
                Ethically invest and pursue capital gains with gold
              </p>
            </div>
          </div>
        </ParallaxLayer>
      </Parallax>
          <div
          // sticky={{ start: 0, end: 1 }}
          style={{ justifyContent: 'center', zIndex: -1, position:'sticky' }}
        >
          <span className="flex items-center justify-center py-80 px-9 md:px-24 lg:px-28">
            <div>
              <p className="text-center text-4xl md:text-6xl font-semibold text-[#010103]/80">
                The Gold <br />
                Convenience
              </p>
              <p className="py-4 text-center text-xl font-normal text-[#010103]/50">
                Reinventing the nation’s growing digital <br /> economy to lead the way into a new
                era of <br />
                responsible, trustworthy gold in <br /> Bangladesh.
              </p>
            </div>
          </span>
        </div>
    </div>
  );
}
