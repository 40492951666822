import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/ui/Navbar';
import Footer from '../pages/landing/Footer';

function Layout() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center">
          <h1 className="text-3xl font-bold animate-pulse lg:text-5xl text-secondary">
            Gold
            <span className="text-transparent text-primary">
            Kinen
            </span>
          </h1>
        </div>
      }
    >
      <div className="w-full">
        <div className="sticky top-0 z-50">
          <Navbar />
        </div>
        <div className=''>
          <Outlet />
        </div>
       <Footer/>
      </div>
    </Suspense>
  );
}

export default Layout;
