import { FaFacebook, FaFacebookMessenger, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ImageContainer from '../../components/ui/ImageContainer';

function Footer() {
  return (
    <div>
      <div className="bg-footerBg py-8 px-9 text-white md:px-20 lg:px-28">
        <div className="justify-between border-b border-[#B5BAC4]/20 pb-8 md:flex">
          <div>
            <ImageContainer
              src="image/navbar/gklogo.png"
              alt="gklogo"
              className="h-18 w-44 object-scale-down md:max-w-md xs:object-fill"
            />
          </div>
          <div>
            {' '}
            <div className="mt-4 flex space-x-6">
              <Link to="#" className="block">
                <FaFacebook size={20} />
              </Link>
              <Link to="#" className="block">
                <FaFacebookMessenger size={20} />
              </Link>
              <Link to="#" className="block">
                <FaTwitter size={20} />
              </Link>
              <Link to="#">
                <FaLinkedinIn size={20} />
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden gap-4 py-4 pt-8 lg:visible lg:grid lg:grid-cols-4">
          <div className="space-y-4">
            {/* <Link to="/">
              <p className="text-sm font-normal text-[#FFFFFF]">Compliance</p>
            </Link> */}
            <a href="/#faq" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">FAQ</p>
            </a>
            <a href="/#how-it-works" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">How it works</p>
            </a>
            <a href="/#contact-us" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">Contact Us</p>
            </a>
          </div>
          <div className="space-y-4">
            <a href="/#about-us" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">About Us</p>
            </a>
            <Link to="/privacy-policy" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">Privacy Policy</p>
            </Link>
            <Link to="/terms-of-use" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">Terms and Conditions</p>
            </Link>
          </div>
          <div className="space-y-4">
            <Link to="/refund-policy" className="block">
              <p className="text-sm font-normal text-[#FFFFFF]">Refund Policy</p>
            </Link>
          </div>
          <div className="space-y-2">
            <p className="text-xl font-bold text-white">Download Gold Kinen App</p>
            <p className="pb-4 text-sm font-normal leading-6 text-[#FFFFFF]/50">
              Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
              today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
              yourself.
            </p>

            <button className="rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br focus:outline-none px-8 py-3 text-base font-semibold text-primary hover:text-white">
              Download App
            </button>
          </div>
        </div>
        <div className="lg:hidden">
          <div>
            <div className="grid grid-cols-2 gap-4 py-4 pt-8 md:grid-cols-3 ">
              <div className="space-y-4">
                {/* <Link to="/">
                  <p className="text-sm font-normal text-[#FFFFFF]">Compliance</p>
                </Link> */}
                <a href="/#faq" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">FAQ</p>
                </a>
                <a href="/#how-it-works" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">How it works</p>
                </a>
                <a href="/#contact-us" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">Contact Us</p>
                </a>
                <a href="/#about-us" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">About Us</p>
                </a>
              </div>
              <div className="space-y-4">
                
                <a href="/privacy-policy" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">Privacy Policy</p>
                </a>
                
                <a href="/terms-of-use" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">Terms and Conditions</p>
                </a>
                <a href="/refund-policy" className="block">
                  <p className="text-sm font-normal text-[#FFFFFF]">Refund Policy</p>
                </a>
              </div>
              {/* <div className="space-y-4">
                <Link to="/terms-of-use">
                  <p className="text-sm font-normal text-[#FFFFFF]">Terms of use</p>
                </Link>
                <Link to="/refund-policy">
                  <p className="text-sm font-normal text-[#FFFFFF]">Refund Policy</p>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="w-full py-16 md:w-96">
            <div className="space-y-2">
              <p className="text-xl font-bold">Download Gold Kinen App</p>
              <p className="pb-4 text-sm font-normal text-[#FFFFFF]/50">
                Bangladesh’s First Hallmark Certified 22k Gold Buy, Sell and Storage App. Download
                today to Buy Gold for as low as 500 BDT and Refer for a chance to win Gold for
                yourself.
              </p>

              <button className="rounded-lg bg-gradient-to-r from-[#e9be5a] to-[#c6952c] hover:bg-gradient-to-br py-3 px-14 text-base font-semibold text-primary hover:text-white">
                Download App
              </button>
            </div>
          </div>
        </div>
        <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-2">
          Trade License No: TRAD/DNCC/037208/2022
        </p>
        <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-2">
          Registered Address: House-84, Level-6, Road-11, Block-D, Banani, Dhaka-1213
        </p>
        <p className="text-start text-sm font-normal text-[#FFFFFF]/60 md:py-4 lg:py-0">
          © 2022 GoldKinen Technologies Ltd. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
