import React from 'react';
import { useRoutes } from 'react-router-dom';
import Layout from '../layout';
import { Eula } from '../pages/landing/Eula';
import GoldConvenience from '../pages/landing/goldConvenience';
import GoldKinen from '../pages/landing/GoldKinen';
import MoreFaq from '../pages/landing/MoreFaq';
import { PrivacyPolicy } from '../pages/landing/PrivacyPolicy';
import { RefundPolicy } from '../pages/landing/RefundPolicy';
import Services from '../pages/landing/Services';
// import TermsOfUse from '../pages/landing/TermsOfUse';

const Landing = React.lazy(() => import('../pages/landing'));
const NotFound = React.lazy(() => import('../pages/notFound'));

function Routes() {
  const routes = {
    path: '/',
    element: <Layout />,
    children: [
      { path: '/', element: <Landing /> },
      { path: '/services', element: <Services /> },
      { path: '/gold-kinen', element: <GoldKinen /> },
      // { path: '/how-it-works', element: <HowItWorks /> },
      { path: '/goldConvenience', element: <GoldConvenience /> },
      { path: '/more-faq', element: <MoreFaq /> },
      { path: '/terms-of-use', element: <Eula /> },
      { path: '/privacy-policy', element: <PrivacyPolicy /> },
      { path: '/eula', element: <Eula /> },
      { path: '/refund-policy', element: <RefundPolicy /> },
    ],
  };

  const notFound = {
    path: '/not-found',
    element: <NotFound />,
  };

  return useRoutes([routes, notFound]);
}

export default Routes;
